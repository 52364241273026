import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, TextField, Button, FormControl,Alert ,Snackbar, InputLabel, Select, MenuItem, Paper, Typography, IconButton, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Switch, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Container } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import QuillToolbar, { modules, formats } from './quilltoolbar.js';
import 'react-quill/dist/quill.snow.css';
import { modeContext } from '../../context/modeContext.js'
//import { ToastContainer } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
const AlertasConfigurables = () => {
    const { token, tokenCompany } = React.useContext(modeContext)
    const [tabIndex, setTabIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [destinatariosModalOpen, setDestinatariosModalOpen] = useState(false);
    const [ccModalOpen, setCcModalOpen] = useState(false);
    const [generarExcel, setGenerarExcel] = useState(false);
    const [destinatarios, setDestinatarios] = useState([""]); 
    const [cc, setCc] = useState([""]);  
    const [alertas, setAlertas] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const [formData, setFormData] = useState({
        NombreAlerta: '',
        Descripcion: '',
        ProcesoAlerta: '',
        FrecuenciaEnvio: '',
        HoraEnvio: '00',  
        MinutoEnvio: '',
        Destinatarios: '',
        CC: '',
        AsuntoCorreo: '',
        CuerpoCorreo: '',
        Activo: true
    });
    
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formErrors = {};
    
        if (!formData.NombreAlerta || !formData.NombreAlerta.trim()) {
            formErrors.NombreAlerta = "El nombre de la alerta es obligatorio";
        }
        if (!formData.Descripcion || !formData.Descripcion.trim()) {
            formErrors.Descripcion = "La descripción es obligatoria";
        }
        if (!formData.ProcesoAlerta) {
            formErrors.ProcesoAlerta = "El tipo de mantenimiento es obligatorio";
        }
        if (!formData.FrecuenciaEnvio) {
            formErrors.FrecuenciaEnvio = "La frecuencia de envío es obligatoria";
        } else {
            if (formData.FrecuenciaEnvio === 'Semanal' && !formData.DiaEnvio) {
                formErrors.DiaEnvio = "El día de la semana es obligatorio para la frecuencia semanal";
            }
            if (formData.FrecuenciaEnvio === 'Mensual' && !formData.DiaEnvio) {
                formErrors.DiaEnvio = "El día del mes es obligatorio para la frecuencia mensual";
            }
        }
        if (!formData.HoraEnvio) {
            formErrors.HoraEnvio = "La hora de envío es obligatoria";
        }
        if (!formData.MinutoEnvio) {
            formErrors.MinutoEnvio = "El minuto de envío es obligatorio";
        }
        if (!formData.Destinatarios || !formData.Destinatarios.trim()) {
            formErrors.Destinatarios = "Al menos un destinatario es obligatorio";
        }
        if (!formData.AsuntoCorreo || !formData.AsuntoCorreo.trim()) {
            formErrors.AsuntoCorreo = "El asunto del correo es obligatorio";
        }
        if (!formData.CuerpoCorreo || !formData.CuerpoCorreo.trim()) {
            formErrors.CuerpoCorreo = "El cuerpo del correo es obligatorio";
        }
        
        const destinatariosInvalidos = destinatarios.filter(email => !validateEmail(email));
        const ccInvalidos = cc.filter(email => !validateEmail(email));
    
        if (destinatariosInvalidos.length > 0) {
            formErrors.Destinatarios = "Todos los destinatarios deben ser correos válidos";
        }
        if (cc.length > 0) {
            const ccInvalidos = cc.filter(email => email && !validateEmail(email));
            if (ccInvalidos.length > 0) {
                formErrors.CC = "Todos los CC deben ser correos válidos";
            }
        }
    
        setErrors(formErrors);
    
        if (Object.keys(formErrors).length > 0) {
            setSnackbarMessage(Object.values(formErrors)[0]);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return false;
        }
    
        return true;
    };
    
    
    const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }], 
    [{ header: [1, 2, 3, false] }], 
    [{ color: [] }, { background: [] }], 
    ["link", "image"],
    ["clean"], 
  ];

  
    useEffect(() => {
        fetchAlertas();
    }, []);

    const fetchAlertas = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}business/api/alertas-configurables`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    token: token,
                    empresa: tokenCompany,
                    'Cache-Control': 'no-cache', 
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
            });
            const data = await response.json();
            setAlertas(data.data);
        } catch (error) {
            console.error('Error fetching alertas:', error);
        }
    };

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value || '00'  
        }));
    };

    const diasSemana = {
        1: "Lunes",
        2: "Martes",
        3: "Miércoles",
        4: "Jueves",
        5: "Viernes",
        6: "Sábado",
        7: "Domingo"
    };

            const handleModalOpen = (alerta = null) => {
            if (alerta) {
                setFormData({
                    NombreAlerta: alerta.NombreAlerta,
                    Descripcion: alerta.Descripcion,
                    ProcesoAlerta: alerta.ProcesoAlerta,
                    FrecuenciaEnvio: alerta.EstDias ? "Mensual" : alerta.EstSema ? "Semanal" : "Diario",
                    DiaEnvio: alerta.EstSema ? diasSemana[alerta.NroSema] : alerta.EstDias ? alerta.NroDias : "",
                    HoraEnvio: String(alerta.NroHora).padStart(2, '0'),
                    MinutoEnvio: String(alerta.NroMinu).padStart(2, '0'),
                    Destinatarios: alerta.Destinatarios,
                    CC: alerta.CC,
                    AsuntoCorreo: alerta.AsuntoCorreo,
                    CuerpoCorreo: alerta.CuerpoCorreo,
                    Activo: alerta.Activo,
                    IdAlertaConfig: alerta.IdTask,
                });

                setDestinatarios(alerta.Destinatarios ? alerta.Destinatarios.split(", ") : [""]);
                setCc(alerta.CC ? alerta.CC.split(", ") : [""]);
            } else {
                setFormData({
                    NombreAlerta: '',
                    Descripcion: '',
                    ProcesoAlerta: '',
                    FrecuenciaEnvio: '',
                    DiaEnvio: '',
                    HoraEnvio: '',
                    MinutoEnvio: '',
                    Destinatarios: '',
                    CC: '',
                    AsuntoCorreo: '',
                    CuerpoCorreo: '',
                    Activo: true,
                });

                setDestinatarios([""]);
                setCc([""]);
            }
            setModalOpen(true);
        };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleAddDestinatario = () => {
        setDestinatarios([...destinatarios, ""]);
    };

    const handleAddCc = () => {
        setCc([...cc, ""]);
    };

    const handleRemoveField = (setFunction, index, currentValues) => {
        const newValues = currentValues.filter((_, i) => i !== index);
        setFunction(newValues);
    };

    const handleFieldChange = (index, value, setFunction, currentValues) => {
        const newValues = [...currentValues];
        newValues[index] = value;
        setFunction(newValues);
    };

    const handleModalCloseAction = (modalSetter) => {
        modalSetter(false);
    };

    const handleSaveModal = (modalSetter, setField, values) => {
        setField(values.join(", "));
        modalSetter(false);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }
        const alertData = {
            ...formData,
            DiaEnvio: formData.FrecuenciaEnvio === "Semanal" || formData.FrecuenciaEnvio === "Mensual" ? formData.DiaEnvio : null,
            HoraEnvio: formData.HoraEnvio,
            MinutoEnvio: formData.MinutoEnvio,
            GenerarExcel: generarExcel
        };
        const method = formData.IdAlertaConfig ? 'PUT' : 'POST'; 
    
        try {
            const url = formData.IdAlertaConfig 
                ? `${process.env.REACT_APP_API}business/api/alertas-configurables/${formData.IdAlertaConfig}` 
                : `${process.env.REACT_APP_API}business/api/alertas-configurables`;
    
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    token: token,
                    empresa: tokenCompany,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                body: JSON.stringify(alertData)
            });
    
            const result = await response.json();
            if (response.ok) {
                setSnackbarMessage(formData.IdAlertaConfig ? 'Alerta actualizada exitosamente' : 'Alerta guardada exitosamente');
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                fetchAlertas();
                handleModalClose(); 
                await fetchAlertas(); 
            } else {
                setSnackbarMessage(`Error al guardar alerta: ${result.message}`);
                setSnackbarSeverity("error");
                setSnackbarOpen(true);            }
        } catch (error) {
            console.error('Error al guardar alerta:', error);
            setSnackbarMessage("Error al guardar alerta");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const handleQuillChange = (value) => {
        setFormData({ ...formData, CuerpoCorreo: value });
      };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}business/api/alertas-configurables/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    token: token,
                    empresa: tokenCompany,
                }
            });
            
            if (response.ok) {
                setSnackbarMessage('Alerta eliminada exitosamente');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                fetchAlertas(); 
            } else {
                const result = await response.json();
                setSnackbarMessage(`Error al eliminar alerta: ${result.message}`);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error al eliminar alerta:', error);
            setSnackbarMessage('Error al eliminar alerta');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };
    
    const handleToggleActivo = async (id, estado) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}business/api/alertas-configurables/${id}/toggle`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    token: token,
                    empresa: tokenCompany,
                },
                body: JSON.stringify({ Activo: !estado }) 
            });
    
            if (response.ok) {
                setSnackbarMessage('Estado de alerta actualizado');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                fetchAlertas();
            } else {
                const result = await response.json();
                setSnackbarMessage(`Error al actualizar estado de alerta: ${result.message}`);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error al actualizar estado de alerta:', error);
            setSnackbarMessage('Error al actualizar estado de alerta');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };
    
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    return (
        <Container fixed>
        <Box sx={{ padding: 4 }}>
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={6000} 
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Typography variant="h4">Alertas Configurables</Typography>
                <Button variant="contained" color="primary" onClick={handleModalOpen}>
                    Nueva Alerta
                </Button>
            </Box>

            {alertas.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell sx={{ textAlign: 'center' }}>Nombre</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>Descripción</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>Proceso</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>Frecuencia de Envío</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>Fecha de Creación</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {alertas.map((alerta) => (
                                <TableRow key={alerta.IdTask}>
                                    <TableCell sx={{ textAlign: 'center' }}>{alerta.NombreAlerta}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{alerta.Descripcion}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{alerta.ProcesoAlerta}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{alerta.FrecuenciaEnvio}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{new Date(alerta.FechaCreacion).toLocaleDateString()}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>
                                        <Tooltip title="Editar">
                                            <IconButton onClick={() => handleModalOpen(alerta)}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Eliminar">
                                            <IconButton onClick={() => handleDelete(alerta.IdAlertaConfig)} color="error">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={alerta.Activo ? "Desactivar" : "Activar"}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={Boolean(alerta.Activo)} 
                                                    onChange={() => handleToggleActivo(alerta.IdAlertaConfig, alerta.Activo)}
                                                />
                                            }
                                            label="" 
                                        />
                                    </Tooltip>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography variant="body1" color="textSecondary">
                    Actualmente no hay alertas configuradas.
                </Typography>
            )}
                    <Dialog 
                        open={modalOpen} 
                        onClose={handleModalClose} 
                        fullWidth 
                        maxWidth="sm" 
                        sx={{ '& .MuiDialog-paper': { borderRadius: 2 } }}
                    >
                       <DialogTitle>
                        <Typography variant="h2">Nueva Alerta</Typography> 
                        <IconButton 
                            aria-label="close" 
                            onClick={handleModalClose} 
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                        <DialogContent dividers>
                            <Tabs value={tabIndex} onChange={handleTabChange} centered>
                                <Tab label="Datos Generales" />
                                <Tab label="Destinatarios" />
                                <Tab label="Correo" />
                            </Tabs>

                            <Box sx={{ minHeight: '410px', display: 'flex', flexDirection: 'column' }}>
                                <TabPanel value={tabIndex} index={0}>
                                    <TextField label="Nombre de Alerta" name="NombreAlerta" value={formData.NombreAlerta} onChange={handleInputChange} fullWidth margin="normal" />
                                    <TextField label="Descripción" name="Descripcion" value={formData.Descripcion} onChange={handleInputChange} fullWidth margin="normal" />
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth margin="normal">
                                                <InputLabel>Proceso Alerta</InputLabel>
                                                <Select
                                                    name="ProcesoAlerta"
                                                    value={formData.ProcesoAlerta||''}
                                                    onChange={handleInputChange}
                                                >
                                                    <MenuItem value="Mantenimiento Preventivo">Mantenimiento Preventivo</MenuItem>
                                                    <MenuItem value="Mantenimiento Predictivo">Mantenimiento Predictivo</MenuItem>
                                                    </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth margin="normal">
                                                <InputLabel>Frecuencia de Envío</InputLabel>
                                                <Select
                                                    name="FrecuenciaEnvio"
                                                    value={formData.FrecuenciaEnvio}
                                                    onChange={handleInputChange}
                                                >
                                                    <MenuItem value="Diario">Diario</MenuItem>
                                                    <MenuItem value="Semanal">Semanal</MenuItem>
                                                    <MenuItem value="Mensual">Mensual</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {["Diario", "Semanal", "Mensual"].includes(formData.FrecuenciaEnvio) && (
                                        <>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} alignItems="center">
                                                    {formData.FrecuenciaEnvio === "Semanal" && (
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth margin="normal">
                                                                <InputLabel>Día de la Semana</InputLabel>
                                                                <Select
                                                                    name="DiaEnvio"
                                                                    value={formData.DiaEnvio}
                                                                    onChange={handleInputChange}
                                                                >
                                                                    <MenuItem value="Lunes">Lunes</MenuItem>
                                                                    <MenuItem value="Martes">Martes</MenuItem>
                                                                    <MenuItem value="Miércoles">Miércoles</MenuItem>
                                                                    <MenuItem value="Jueves">Jueves</MenuItem>
                                                                    <MenuItem value="Viernes">Viernes</MenuItem>
                                                                    <MenuItem value="Sábado">Sábado</MenuItem>
                                                                    <MenuItem value="Domingo">Domingo</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    )}
                                                    {formData.FrecuenciaEnvio === "Mensual" && (
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth margin="normal">
                                                                <InputLabel>Día del Mes</InputLabel>
                                                                <Select
                                                                    name="DiaEnvio"
                                                                    value={formData.DiaEnvio}
                                                                    onChange={handleInputChange}
                                                                >
                                                                    {Array.from({ length: 31 }, (_, i) => (
                                                                        <MenuItem key={i + 1} value={i + 1}>
                                                                            {i + 1}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    )}

                                                    <Grid item xs={4} sm={4}>
                                                        <FormControl fullWidth margin="normal">
                                                            <InputLabel>Hora</InputLabel>
                                                            <Select
                                                            name="HoraEnvio"
                                                            value={formData.HoraEnvio || '00'} 
                                                            onChange={handleInputChange}
                                                        >
                                                            {Array.from({ length: 24 }, (_, i) => (
                                                                <MenuItem key={i} value={String(i).padStart(2, '0')}>
                                                                    {String(i).padStart(2, '0')}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={4} sm={4}>
                                                        <FormControl fullWidth margin="normal">
                                                            <InputLabel>Minuto</InputLabel>
                                                            <Select
                                                                name="MinutoEnvio"
                                                                value={formData.MinutoEnvio}
                                                                onChange={handleInputChange}
                                                            >
                                                                {Array.from({ length: 59 }, (_, i) => (
                                                                    <MenuItem key={i + 1} value={String(i + 1).padStart(2, '0')}>
                                                                        {String(i + 1).padStart(2, '0')}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                    <FormControlLabel control={<Switch checked={generarExcel} onChange={(e) => setGenerarExcel(e.target.checked)} />} label="Generar Excel" sx={{ marginTop: 2 }} />
                                </TabPanel>

                                <TabPanel value={tabIndex} index={1}>
                                    <TextField label="Destinatarios" name="Destinatarios" value={formData.Destinatarios || destinatarios.join(", ")} fullWidth margin="normal" InputProps={{ endAdornment: <IconButton onClick={() => setDestinatariosModalOpen(true)}><AddIcon /></IconButton> }}  />
                                    <TextField label="CC" name="CC" value={formData.CC || cc.join(", ")} fullWidth margin="normal" InputProps={{ endAdornment: <IconButton onClick={() => setCcModalOpen(true)}><AddIcon /></IconButton> }}  />
                                </TabPanel>
                                <TabPanel value={tabIndex} index={2}>
                                <Box sx={{ minHeight: '400px', display: 'flex', flexDirection: 'column' }}>
                                    {tabIndex === 2 && (
                                    <>
                                        <TextField
                                        label="Asunto del Correo"
                                        name="AsuntoCorreo"
                                        value={formData.AsuntoCorreo}
                                        onChange={(e) => setFormData({ ...formData, AsuntoCorreo: e.target.value })}
                                        fullWidth
                                        margin="dense"
                                        sx={{ mb: 3 }} 
                                        />
                                        <QuillToolbar />
                                        <ReactQuill
                                        value={formData.CuerpoCorreo}
                                        onChange={handleQuillChange}
                                        modules={modules}
                                        formats={formats}
                                        theme="snow"
                                        style={{ height: 200 }}
                                        />
                                    </>
                                    )}
                                </Box>
                                </TabPanel>
                        </Box></DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="primary" onClick={handleSubmit}>Guardar</Button>
                            <Button variant="contained" color="error" onClick={handleModalClose}>Cancelar</Button>
                        </DialogActions>
                    </Dialog>

            <Dialog open={destinatariosModalOpen} onClose={() => handleModalCloseAction(setDestinatariosModalOpen)} maxWidth="sm" fullWidth>
                <DialogTitle sx={{ fontSize: '1.5rem' }}>Agregar Destinatarios</DialogTitle>
                <DialogContent>
                    {destinatarios.map((email, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <TextField label={`Destinatario ${index + 1}`} value={email} onChange={(e) => handleFieldChange(index, e.target.value, setDestinatarios, destinatarios)} fullWidth margin="normal" />
                            {destinatarios.length > 1 && <IconButton onClick={() => handleRemoveField(setDestinatarios, index, destinatarios)} color="error"><RemoveIcon /></IconButton>}
                        </Box>
                    ))}
                    <Button variant="outlined" onClick={handleAddDestinatario}><AddIcon /> Agregar Destinatario</Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleModalCloseAction(setDestinatariosModalOpen)} color="error">Cancelar</Button>
                    <Button onClick={() => handleSaveModal(setDestinatariosModalOpen, (value) => setFormData({...formData, Destinatarios: value}), destinatarios)} color="primary">Guardar</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={ccModalOpen} onClose={() => handleModalCloseAction(setCcModalOpen)} maxWidth="sm" fullWidth>
                <DialogTitle sx={{ fontSize: '1.3rem' }}>Agregar CC</DialogTitle>
                <DialogContent>
                    {cc.map((email, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <TextField label={`CC ${index + 1}`} value={email} onChange={(e) => handleFieldChange(index, e.target.value, setCc, cc)} fullWidth margin="normal" />
                            {cc.length > 1 && <IconButton onClick={() => handleRemoveField(setCc, index, cc)} color="error"><RemoveIcon /></IconButton>}
                        </Box>
                    ))}
                    <Button variant="outlined" onClick={handleAddCc}><AddIcon /> Agregar CC</Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleModalCloseAction(setCcModalOpen)} color="error">Cancelar</Button>
                    <Button onClick={() => handleSaveModal(setCcModalOpen, (value) => setFormData({...formData, CC: value}), cc)} color="primary">Guardar</Button>
                </DialogActions>
            </Dialog>
        </Box>
        </Container>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

export default AlertasConfigurables;
